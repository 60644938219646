<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-footer="true"
    :hide-default-header="true"
    :disable-pagination="true"
    dense
    class="transparent"
    style="width: 95%"
  >
    <!-- top template-->
    <template v-slot:top>
      <v-toolbar v-if="!readonly" flat density="compact">
        <v-toolbar-title >{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="550px"
          small
        >
        <template v-slot:activator="{ on, props }">
          <v-btn
            class="mb-0"
            color="primary"
            v-bind="props"
            x-small
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="mb-0 pb-0">
            <v-container>
                <v-row>
                  <v-col
                    v-for="(field, index) in fields"
                    :key="index"
                    cols="12"
                  >
                    <v-text-field
                      v-if="field.type === 'string'"
                      v-model="editedItem[field.name]"
                      :label="$cvutils.getTranslatedConfigField(config, field.name)"
                      :rules="getFieldRule(field.name)"
                      dense
                      clearable
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="field.type === 'password'"
                      v-model="editedItem[field.name]"
                      :type="showSecret ? 'text' : 'password'"
                      :label="$cvutils.getTranslatedConfigField(config, field.name)"
                      :rules="getFieldRule(field.name)"
                      dense
                    >
                      <template v-slot:append>
                        <!-- control if the caller response input field is starred out or not -->
                        <v-icon @click="showSecret = !showSecret">
                          {{ showSecret ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-select
                      v-if="field.type === 'enum'"
                      v-model="editedItem[field.name]"
                      :items="getRestrictedValues(field.name)"
                      :label="$cvutils.getTranslatedConfigField(config, field.name)"
                      item-value="value"
                      item-text="display"
                      dense
                    ></v-select>
                    <v-text-field
                      v-if="field.type === 'readonly'"
                      v-model="editedItem[field.name]"
                      :label="$cvutils.getTranslatedConfigField(config, field.name)"
                      :disabled="editedIndex > -1"
                      :rules="editedIndex == -1 ? getFieldRule(field.name) : []"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="closeDialog()"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              color="success"
              :disabled="!validateItem(editedItem)"
              @click="saveItem()"
            >
              <translate>Save</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-toolbar>
    </template>
    <!-- header template-->
    <template
      v-if="!readonly"
      v-slot:header="{ props: { headers } }"
    >
      <thead>
        <tr class="text-left pa-0 ma-0">
          <th
            :class="{ 'text-right pa-0': header.value === 'actions', 'text-left pr-3 pl-0': header.value !== 'actions' }"
            v-for="(header, index) in headers"
            :key = index
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
    </template>
    <!-- item template-->
    <template v-slot:item="{ item }">
      <tr class="text-left">
        <td
          :class="{ 'text-right': header.value == 'actions' }"
          class="pa-0"
          v-for="(header, index) in headers"
          :key="index"
        >
          <div
            v-if="!readonly && header.value == 'actions'"
            style="white-space: nowrap;"
            class="mr-0 pr-0"
          >
            <v-icon
              v-if="!readonly && header.value == 'actions'"
              small
              class="mr-1 pl-1"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="!readonly && header.value == 'actions'"
              small
              class="mr-1 pl-0"
              @click="deleteItem(item)"
            >
              mdi-trash-can
            </v-icon>
          </div>
          <div
            v-if="header.value != 'actions'"
            class="pr-3"
          >
            <v-tooltip top min-width="300px">
              <template v-slot:activator="{on, attrs}">
                <span v-bind="attrs" v-on="on">
                  {{ item[header.value] ? item[header.value] : header.default }}
                </span>
              </template>
              <v-container>
                <v-row
                  v-for="(field, index) in fields"
                  :key="index"
                  no-gutters
                >
                  <v-col cols="3">
                    {{ $cvutils.getTranslatedConfigField(config, field.name) }}
                  </v-col>
                  <v-col cols="9">
                    {{ formatField(field.type, item[field.name]) }}
                  </v-col>
                </v-row>
              </v-container>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { translate } from 'vue-gettext';
const { gettext: $gettext } = translate;

export default {
  name: 'EditableObjectList',

  props: {
    value: [],
    schema: [],
    title: String,
    readonly: Boolean,
    config: String
  },
  data: () => ({
    items: [],
    dialog: false,
    showSecret: false,
    editedItem: {},
    editedIndex: -1,
  }),
  computed: {
    headers() {
      let __headers = [];
      // add in headers for all elements in the schema.
      this.schema.forEach(field => {
        // only display fields that we configured to show in the summary.
        if (field.display) {
          __headers.push({ text: this.$cvutils.getTranslatedConfigField(this.config, field.name), type: field.type, align: "left", sortable: false, value: field.name });
        }
      });
      // add in column for trashcan/delete.
      __headers.push({
        text: $gettext("Action"),
        type: null,
        align: "right",
        sortable: false,
        value: "actions"
      });
      return __headers;
    },
    fields() {
      let __fields = [];
      this.schema.forEach(field => {
        __fields.push({ name: field.name, type: field.type, default: field.default });
      });
      return __fields;
    }
  },
  mounted() {
    this.items = this.arrayToDataTable(this.value);
    this.editedItem = this.initializeItem();
  },
  watch: {
    value() {
      if (this.readonly) {
        this.items = this.arrayToDataTable(this.value);
      }
    }
  },
  methods: {
    formatField(type, text) {
      if (type == 'password') {
        return '******';
      }
      else {
        let maxlength = 75;
        let truncated = text;
        if (text && (text.length > maxlength)) {
          truncated = truncated.substring(0, maxlength) + '...';
        }
        return truncated;
      }
    },
    getFieldSchema(id) {
      for (let field of this.schema) {
        if (field.name === id) {
          return field;
        }
      }
      return null;
    },
    getFieldRule(field_id) {
      let rules = [];
      let field = this.getFieldSchema(field_id);
      if (field && field.rule) {
        rules = [ value => {
          if (value && value !== '') {
            let re = new RegExp(field.rule.rule, "i");
            if (re.test(value)) return true;
            return $gettext(field.rule.message_id);
          }
          else return true;
        }]
      }
      return rules;
    },
    getRestrictedValues(field_id) {
      let rvs = [];
      let field = this.getFieldSchema(field_id);
      if (field && field.values) {
        field.values.forEach((rv) => {
          rvs.push({display: $gettext(rv), value: rv});
        });
      }
      return rvs;
    },
    arrayToDataTable(array) {
      let dataTable = [];
      array.forEach(item => {
        dataTable.push(item);
      })
      return dataTable;
    },
    dataTableToArray(dataTable){
      let array = []
      dataTable.forEach(item => {
        array.push(item);
      })
      return array;
    },
    initializeItem() {
      let defaultItem = {};
      this.fields.forEach(field => {
        defaultItem[field.name] = field.default || '';
      });
      return defaultItem;
    },
    validateItem(item) {
      for (let field of this.schema) {
        // if the field is required and we don't have a value fail out.
        if (field.required && (!item[field.name] || item[field.name] === '')) {
          return false;
        }
        // validate any rules as well.
        if (field.rule && field.rule.rule) {
          let re = new RegExp(field.rule.rule, "i");
          if (!re.test(item[field.name])) {
            return false;
          }
        }
      }
      return true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      let deleteIndex = this.items.indexOf(item);
      if (deleteIndex >= 0 && deleteIndex < this.items.length) {
        this.items.splice(deleteIndex, 1);
        this.$emit('input', this.dataTableToArray(this.items));
      }
    },
    saveItem() {
      // if this was an modified existing item.
      if (this.editedIndex  > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      }
      else {
        this.items.push(this.editedItem);
      }
      this.$emit('input', this.dataTableToArray(this.items));
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = this.initializeItem();
          this.editedIndex = -1;
        });
    }
  }
}
</script>
<style scoped>
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}

</style>