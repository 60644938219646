<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <v-container style="max-width: 65%;">
      <v-row justify-space-around align-content-center>
        <v-col>
          <v-select
            id="cv_selected_report"
            v-model="selectedReport"
            :label="$gettext('Usage Report to view')"
            @change="loadReport()"
            :items="usageReports"
          >
            <template v-slot:item="{ item }">
              <option :value="item.value">
                <translate :key="item.value">{{ item.text }}</translate>
              </option>
            </template>
            <template v-slot:selection="{ item }">
              <translate :key="item.value">{{ item.text }}</translate>
            </template>
          </v-select>
        </v-col>

        <v-col>
          <v-menu
            ref="dateRangeMenu"
            v-model="dateRangeMenu"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              range
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn
                small
                color="primary"
                @click="dateRangeMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                small
                color="primary"
                :disabled = 'dateRange.length !== 2'
                @click="dateRangeText = dateRange.join(' - '); $refs.dateRangeMenu.save(dateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$gettext('Filter results')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-col>

        <v-col
          v-if="this.reportOutput.length > 0"
          sm="1" md="1" lg="1"
          class="mt-3">
          <v-icon
            large
            :title="$gettext('Report Download')"
            @click="downloadReport()"
          >mdi-download-circle-outline</v-icon>
        </v-col>
      </v-row>

      <v-data-table
        id="cv_usage_reports_table"
        :headers="headers"
        :items="reportOutput"
        :loading="reportLoading"
      >
        <template
          v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          <translate :key="header.value">{{ header.text }}</translate>
        </template>
        <template v-slot:item="{ item, headers }">
          <tr>
            <td v-for="header in headers" :key="header.text">
              <span v-if="header.translate">
                <translate :key="item.id">{{ item[header.value] }}</translate>
              </span>
              <span v-else>
                {{ item[header.value] }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>

    <!-- Hidden download link element -->
    <a ref="downloadLink" class="hide" :href="dataURL" :download="dataFilename">Report Download</a>

    <NetworkStatus />

    <NotificationDialog
      v-model="showNotification"
      :notification="notification"
      @close="showNotification = false"
    />

  </v-container>
</template>

<script>
import { translate } from 'vue-gettext';

import NetworkStatus from '@/components/NetworkStatus';
import NotificationDialog from '@/components/NotificationDialog';

// eslint-disable-next-line no-unused-vars
const { gettext: $gettext, gettextInterpolate} = translate;

export default {
  name: 'UsageReports',

  components: {
    NetworkStatus,
    NotificationDialog
  },

  // Component properties
  //
  // settings - additional configuration settings for reports including:
  // * feature flags enabled/disabled.
  //
  props: {
    settings: Object
  },

  data: () => ({
    usageReports: [],
    selectedReport: '',
    reportLoading: false,
    dataURL: '#',
    dataFilename: 'unknown',

    dateRange: [],
    dateRangeText: null,
    dateRangeMenu: false,

    search: '',
    headers: [],
    reportOutput: [],

    errorMessage: '',

    showNotification: false,
    notification: {
      showAuthBtn: false,
      timeout: 5000,
      title: '',
      body: '',
      type: 'info',
      variables: null
    }
  }),
  watch: {
    dateRangeText: function () {
      this.loadReport();
    }
  },
  methods: {
    async downloadReport() {
      const {status, notification, filename, reportCSV} = await this.$cvApi.reportDownload(this.selectedReport, this.dateRange[0], this.dateRange[1]);
      if(status) {
        const blob = new Blob([reportCSV], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        this.dataURL = url;
        this.dataFilename = filename;

        // Delay click
        setTimeout(() => {
          this.$refs.downloadLink.click();
        }, 600);

        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 5000);
      }
      else {
        if(notification) {
          this.notify(notification);
        }
      }
    },
    filterString(value) {
      let found = true;
      if (!this.search || this.search.length === 0) {
        return found;
      }

      // Catch any errors and abort filtering
      try {
        found = $gettext(value).toLowerCase().includes(this.search.toLowerCase());
      } catch {
        found = true;
      }
      return found;
    },
    async loadReport() {

      this.headers = [];
      this.reportOutput = [];

      if (!this.selectedReport) {
        return;
      }

      // start the report loading spinner.
      this.reportLoading = true;

      const {status, notification, headers, output} = await this.$cvApi.reportGet(this.selectedReport, this.dateRange[0], this.dateRange[1]);
      if(status) {
        this.headers = headers;
        this.reportOutput = output;
        // Check header data for custom filtering (supports translated values)
        this.headers.forEach(header => {
          if (header.filterable) {
            header.filter = this.filterString;
          }
        });
      }
      else {
        // FIXME KDK: make sure the translated notifications are correct -- include report id.
        if(notification) {
          this.notify(notification);
        }
      }

      // report is no longer loading -- stop the spinner.
      this.reportLoading = false;
    },
    pad(n) {
      return (n < 10 ? '0' + n : n);
    },
    notify(notification) {
      if (!notification) {
        return;
      }
      this.notification = notification
      this.showNotification = true;
    },
    async load() {
      const {status, notification, reportList} = await this.$cvApi.reportList();

      if(status) {
        this.usageReports = reportList;

        // If only a single report is available, select it.
        if (this.usageReports.length === 1) {
          this.selectedReport = this.usageReports[0].value;
        }
      }
      else {
        if(notification) {
          this.notify(notification);
        }
      }
      let today = new Date();
      this.dateRange = [];
      this.dateRange.push(`${today.getFullYear()}-${this.pad(today.getMonth()+1)}-01`);
      this.dateRange.push(`${today.getFullYear()}-${this.pad(today.getMonth()+1)}-${this.pad(today.getDate())}`);
      this.dateRangeText = this.dateRange.join(' - ');
    }
  },
  mounted() {
    this.load();
  }
}
</script>


<style scoped>
/**
 * Temporary fix for breaking change in Vuetify v-grid
 * (see : https://github.com/vuetifyjs/vuetify/issues/11408)
 * TODO - remove this after migration
 */
.row:not([class*="my-"]):not([class*="ma-"]):not([class*="mt-"]):not([class*="mb-"]) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: #eeeeee;
}
</style>
