<template>
  <v-row justify="center">
    <v-dialog v-model="dialogLogDetails" persistent max-width="825">
      <v-card>
        <v-card-title>
          <span id="logs_details_title" v-translate='{caller: userLogin}'>System logs for: "%{ caller }"</span>
          <v-spacer></v-spacer>
          <v-text-field
            id="log_details_search"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          id="cv_log_details"
          dense
          :headers="headers"
          :items="eventlog"
          :search="search"
          :loading="eventsLoading"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.outcome.result="{ item, index }">
            <div v-if="item.outcome.result === 'FAILURE'" :id="'cv_logid_'+index">
              <v-chip color="error" class="my-1">{{ item.outcome.result }}</v-chip>
            </div>
            <div v-else :id="'cv_logid_'+index">
              {{ item.outcome.result }}
            </div>
          </template>
          <template v-slot:item.published="{ item, index }">
            <v-tooltip v-if="settings.featureFlags.enableLogRelativeTimestamps" top>
              <template v-slot:activator="{ on }">
                <div v-on="on" :id="'cv_logid_pub_' + index">
                  {{ formatMoment(item.published) }}
                </div>
              </template>
              <span>
                {{ item.published }}
              </span>
            </v-tooltip>
            <span v-else>
              {{ item.published }}
            </span>
          </template>
          <template v-slot:loading>
            <div id="cv_logs_loading"><translate>Loading system event logs...Please wait</translate></div>
          </template>
          <template v-slot:no-data>
            <div><div id="cv_logs_no_data"><translate>No data available</translate></div></div>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="primary"
              id="cv_log_details_close"
              @click="dialogLogDetails = false"
            >
              <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment';
import {translate} from 'vue-gettext';

const {gettext: $gettext} = translate;

export default {
  name: 'CallerLogDetails',

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  props: {
    profile: Object,
    value: Boolean,
    settings: Object
  },
  computed: {
    // expose a computed parameter that returns the :value property of this
    // component on retrieval and emits an close event to the parent to close
    // the dialog when set to 'false'.
    dialogLogDetails: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.eventlog = [];
          this.$emit('close', 'log-details');
        }
      }
    },
    userLogin() {
      if (this.profile && this.profile.profile) {
        return this.profile.profile.login;
      } else {
        return "";
      }
    },
    headers() {
      return [
        { text: this.t('Time'), value: 'published' },
        { text: this.t('Event'), value: 'displayMessage' },
        { text: this.t('Reason'), value: 'outcome.reason' },
        { text: this.t('Result'), value: 'outcome.result' }
      ];
    }
  },
  watch: {
    dialogLogDetails(newValue) {
        if (newValue) {
          this.loadDetailedLogs(this.profile.id)
        }
    }
  },
  data () {
      return {
        eventsLoading: false,
        search: '',
        eventlog: [],
      }
    },
  methods: {
    formatMoment: function(date) {
      return moment(date, "YYYY-MM-DDThh:mm:ss a").fromNow();
    },
    t: function (transval) {
      return $gettext(transval);
    },
    loadDetailedLogs: async function(userId) {

      // Start the loading indicator
      this.eventsLoading = true;
      this.search = ''

      const {status, notification, events} = await this.$cvApi.getLogs(userId, true);

      if (status) {
        for (var i=0; i<events.length; i++)
        {
          const obj = events[i];
          const date = new Date(obj.published)
          const language = navigator.language
          var datestr = date.toLocaleDateString(language, { year: 'numeric' })+"-"+ date.toLocaleDateString(language, { month: '2-digit' })+ "-" + date.toLocaleDateString(language, { day: '2-digit' })+"T"+date.toLocaleTimeString(language, { hour: '2-digit', minute:'2-digit',second:'2-digit' })
          obj.published = datestr
          this.eventlog.push(obj)
        }
      } else {
        if (notification) {
          this.$emit('notify', notification);
        }
      }
      // stop the events loading indicator.
      this.eventsLoading = false;
    }
  }
};

</script>

<style scoped>

</style>
