import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=a3105250&scoped=true"
import script from "./LanguageSelector.vue?vue&type=script&lang=js"
export * from "./LanguageSelector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3105250",
  null
  
)

export default component.exports