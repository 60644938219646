<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-footer="true"
    :hide-default-header="true"
    :disable-pagination="true"
    dense
    class="transparent"
    style="width: 95%"
  >
    <!-- header template-->
    <template v-slot:top>
      <v-toolbar v-if="!readonly" flat density="compact">
        <v-toolbar-title >{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="400px"
          small
        >
        <template v-slot:activator="{ on, props }">
          <v-btn
            class="mb-0"
            color="primary"
            v-bind="props"
            x-small
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="mb-0 pb-0">
            <v-text-field
              v-model="newItem"
              single-line
              @keyup.enter="newItem ? addItem(newItem) : null"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false; newItem = null"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="!newItem"
              @click="addItem(newItem)"
            >
              <translate>Add</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-toolbar>
    </template>
    <!-- item template-->
    <template v-slot:item="{ item }">
      <tr class="text-left">
        <td
          :class="{ 'text-right': header.value == 'delete' }"
          class="pa-0"
          v-for="(header, index) in headers"
          :key="index"
        >
          <v-icon
            v-if="!readonly && header.value == 'delete'"
            small
            class="mr-2 pl-1"
            @click="deleteItem(item)"
          >
            mdi-trash-can
          </v-icon>
          <v-edit-dialog
            v-if="!readonly && header.value != 'delete'"
            :return-value.sync="item.value"
            large
            @save="saveItem()"
          >
            {{ item.value }}
            <template v-slot:input>
              <v-text-field
                v-model="item.value"
                single-line
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <div v-else-if="header.value == 'value'">
            {{ item.value }}
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'EditableList',

  props: {
    value: [],
    title: String,
    readonly: Boolean,
  },
  data: () => ({
    headers: [
      {
        text: "Value",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "Delete",
        align: "right",
        sortable: false,
        value: "delete"
      }
    ],
    items: [],
    dialog: false,
    newItem: null
  }),
  mounted() {
    this.items = this.arrayToDataTable(this.value);
  },
  watch: {
    value() {
      if (this.readonly) {
        this.items = this.arrayToDataTable(this.value);
      }
    }
  },
  methods: {
    arrayToDataTable(array) {
      let dataTable = []
      array.forEach(item => {
        dataTable.push ({value: item})
      })
      return dataTable;
    },
    dataTableToArray(dataTable) {
      let array = []
      dataTable.forEach(item => {
        array.push(item.value);
      })
      return array;
    },
    addItem(item) {
      this.items.push({value: item});
      this.$emit('input', this.dataTableToArray(this.items));
      this.dialog = false;
      this.newItem = null;
    },
    deleteItem(item) {
      let deleteIndex = this.items.indexOf(item);
      if (deleteIndex >= 0 && deleteIndex < this.items.length) {
        this.items.splice(deleteIndex, 1);
        this.$emit('input', this.dataTableToArray(this.items));
      }
    },
    saveItem() {
      this.$emit('input', this.dataTableToArray(this.items));
    }
  }
}
</script>
<style scoped>
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}

</style>