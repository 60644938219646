<template>
    <v-snackbar
      app
      v-model="showNotification"
      :timeout="timeout"
      :color="type"
      :width="calculatedWidth"
      vertical
      :centered="$appConfig.widget"
    >
      <div
        id="cv_notif_title"
        class="text-h5 mb-3"
      >{{ title }}</div>
      <div id="cv_notif_body" style="white-space: pre-line;">{{ body }}</div>
      <div v-if="notification.showReload" id="cv_reload_msg"><br><div v-translate>Click Reload to force an update now.</div></div>

      <template v-slot:action>
        <v-btn
          id="cv_notif_btn_login"
          v-if="notification.showAuthBtn"
          text
          @click="$auth.signInWithRedirect({ originalUri: '/verify' })"
        >
          <translate>Login</translate>
        </v-btn>
        <v-btn
          id="cv_notif_btn_reload"
          v-if="notification.showReload"
          text
          @click="reloadPage"
        >
          <translate>Reload</translate>
        </v-btn>
        <v-btn
          id="cv_notif_btn_close"
          v-if="!notification.showAuthBtn"
          text
          @click.native="showNotification = false"
        >
          <div>
            <translate>Close</translate>
          </div>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import {translate} from 'vue-gettext';
const {gettext: $gettext, gettextInterpolate} = translate;

export default {
  name: 'NotificationDialog',

  props: {
    notification: Object,
    value: Boolean,
  },
  computed: {
    calculatedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100%'
        default: return '400px'
      }
    },
    title: {
      get() {
        return $gettext(this.notification.title || 'Unknown');
      }
    },
    body: {
      get() {
        let translatedBody = $gettext(this.notification.body || 'Unknown notification.');
        if(this.notification.variables) {
          translatedBody = gettextInterpolate(translatedBody, this.notification.variables);
        }
        return translatedBody;
      }
    },
    type: {
      get() {
        return this.notification.type || 'info';
      }
    },
    timeout: {
        get() {
            return this.notification.timeout || 5000;
        }
    },
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the notification when set to 'false'.
    showNotification: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close');
        }
      }
    }
  },
  methods: {
    reloadPage() {
        window.location.reload()
    }
  }
}
</script>
